.app-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  header img {
    max-width: 100%;
  }
  
  .download-link {
    color: #62B6E7;
    text-decoration: none;
  }

  .download-link:hover {
    color: #356f91;
  }

  .instruction-item {
    margin: 30px 0;
    border-top: 1px dashed #aaa;
    padding-top: 20px;
  }
  
  .instruction-item h2 {
    font-size: 1.5rem;
  }
  
  .instruction-item p {
    margin-bottom: 15px;
  }
  
  .instruction-content img {
    max-width: 100%;
  }
  
  .instruction-content input[type="file"] {
    display: block;
    margin: 20px auto;
  }
  
  ul {
    padding-left: 20px;
  }
  
  ul li {
    list-style: disc;
    margin: 5px 0;
  }
  
  p {
    line-height: 1.5;
  }